.App {
  padding: 0 2em;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  text-align: center;
  margin-top: 0.5em;
}

.remirror-toolbar {
  flex-flow: row wrap;
}

.App-footer {
  margin-top: 3em;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  text-align: center;
}

.App-phrase-logo {
  margin: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1vmin);
  text-align: center;
}

.App-footer-inboarding {
  font-size: 12px;
}
